import React from 'react'
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom'
import { useAsync } from 'react-async'
import { PDFViewer, BlobProvider } from '@react-pdf/renderer'
import PdfTemplate from './components/PdfTemplate'
import CatalogueTemplate from './components/CatalogueTemplate'
import { orderBy } from 'lodash'

const App = () => (
  <div className="App">
    <Router>
      <Switch>
        <Route exact path="/products/:sku">
          <CreateProductPDF />
        </Route>
        <Route exact path="/catalogue">
          <CatalogueGenerator />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  </div>
)

function Button(props) {
  return (
    <div className="page">
      <div>
        <button className="button" onClick={props.onClick}>
          {props.lable}
        </button>
      </div>
    </div>
  )
}

function Loading() {
  return (
    <div className="loading">
      <div>
        <div className="progress">
          <div className="progress-bar"></div>
        </div>
      </div>
    </div>
  )
}

function PageNotFound() {
  return (
    <div className="page-not-found">
      <span>404</span>
      <span>Not Found</span>
    </div>
  )
}

class CatalogueGenerator extends React.Component {
  constructor(props) {
    super(props)
    this.handleGenerate = this.handleGenerate.bind(this)
    this.state = { generate: false }
  }

  handleGenerate() {
    this.setState({ generate: true })
  }

  render() {
    return !this.state.generate ? (
      <Button lable="Katalog erstellen" onClick={this.handleGenerate} />
    ) : (
      <CreateCataloguePDF />
    )
  }
}

const PDFDownloadButton = ({ document: doc, children, fileName = 'document.pdf' }) => {
  if (!doc) {
    console.warn(false, 'You should pass a valid document to PDFDownloadLink')
    return null
  }

  const downloadOnIE = (blob) => () => {
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName)
    }
  }

  return (
    <BlobProvider document={doc}>
      {(params) => (
        <div className="page">
          <div>
            <a
              className="button"
              download={fileName}
              href={params.url}
              lable="Katalog erstellen"
              onClick={downloadOnIE(params.blob)}
            >
              {typeof children === 'function' ? children(params) : children}
            </a>
          </div>
        </div>
      )}
    </BlobProvider>
  )
}

const asyncFetchProduct = async ({ sku }, { signal }) =>
  await fetch(`${process.env.REACT_APP_API_URL}/api/products/${sku}`)
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => res.json())

function CreateProductPDF() {
  let { sku } = useParams()
  const { data, error, isLoading } = useAsync({ promiseFn: asyncFetchProduct, sku: sku })
  if (isLoading) return <Loading />
  if (error) return <PageNotFound />
  if (data)
    return (
      <div>
        <PDFViewer>
          <PdfTemplate data={data.data} />
        </PDFViewer>
      </div>
    )
}

const asyncFetchProducts = async ({ sku }, { signal }) =>
  await fetch(`${process.env.REACT_APP_API_URL}/api/products?limit=999`)
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => res.json())

function CreateCataloguePDF() {
  const { data, error, isLoading } = useAsync({ promiseFn: asyncFetchProducts })

  if (isLoading) return <Loading />

  if (error) return <PageNotFound />

  if (data) {
    const groups = [
      {
        title: 'Stuhl',
        displayTitle: 'Stühle',
        products: [],
      },
      {
        title: 'Armlehnstuhl',
        displayTitle: 'Armlehnstühle',
        products: [],
      },
      {
        title: 'Barhocker',
        displayTitle: 'Barhocker',
        products: [],
      },
      {
        title: 'Sessel',
        displayTitle: 'Sessel',
        products: [],
      },
      {
        title: 'Sofa',
        displayTitle: 'Sofas',
        products: [],
      },
      {
        title: 'Hocker',
        displayTitle: 'Hocker',
        products: [],
      },
      {
        title: 'Tisch',
        displayTitle: 'Tische',
        products: [],
      },
      {
        title: 'Sonnenschirm',
        displayTitle: 'Sonnenschirme',
        products: [],
      },
      {
        title: 'Zubehör',
        displayTitle: 'Zubehör',
        products: [],
      },
    ]

    data.data.forEach((product) => {
      groups.forEach((group) => {
        if (group.title === product.group.title) {
          group.products.push(product)
        }
      })
    })

    groups.forEach((group) => {
      group.products = orderBy(group.products, 'family.title', 'asc')
    })

    return (
      <div>
        <PDFDownloadButton document={<CatalogueTemplate data={groups} />} fileName="goin-project.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Katalog wird erstellt…' : 'Jetzt Katalog herunterladen')}
        </PDFDownloadButton>
      </div>
    )
  }
}

export default App
